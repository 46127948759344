// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container-hc7yC {\n  min-height: 70vh;\n}\n.container-hc7yC .table-bdO5i {\n  margin-top: 2rem;\n}\n.container-hc7yC .links-Nupsh a {\n  margin-right: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/page/User/components/OrderHistory/OrderHistory.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAFA;EAOQ,gBAAA;AAFR;AALA;EAYY,kBAAA;AAJZ","sourcesContent":[".container {\n    min-height: 70vh;\n    .title {\n\n    }\n\n    .table {\n        margin-top: 2rem;\n    }\n\n    .links {\n        a {\n            margin-right: 1rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container-hc7yC",
	"table": "table-bdO5i",
	"links": "links-Nupsh"
};
export default ___CSS_LOADER_EXPORT___;

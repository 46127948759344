// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.signinModal-k4JA7 {\n  width: 100%;\n  max-width: 30rem !important;\n}\n@media (max-width: 576px) {\n  .signinModal-k4JA7 {\n    padding: 0 !important;\n  }\n}\n.linkContainer-FVBcP {\n  display: flex;\n  justify-content: space-between;\n}\n.link-Za7yF {\n  text-align: right;\n  text-decoration: underline;\n  color: grey;\n}\n.link-Za7yF:hover {\n  color: #313131;\n  text-decoration: underline;\n}\n.bottomContainer--UR2W {\n  width: 100%;\n  padding: 1rem 0 0 0;\n  margin-top: 2rem;\n  border-top: 0.0625rem solid #e5e5e5;\n  text-align: center;\n}\n.bottomContainer--UR2W .boldText-kmDZM {\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://src/components/SignIn/SignIn.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAD7F;EACC,WAAA;EACA,2BAAA;AAGD;AADC;EAAA;IACI,qBAAA;EAIH;AACF;AADA;EACC,aAAA;EACA,8BAAA;AAGD;AAAA;EACC,iBAAA;EACA,0BAAA;EACA,WAAA;AAED;AACA;EACC,cAAA;EACA,0BAAA;AACD;AAEA;EACC,WAAA;EACA,mBAAA;EACA,gBAAA;EACA,mCAAA;EACA,kBAAA;AAAD;AALA;EAQE,gBAAA;AAAF","sourcesContent":["@import \"~@/util/util.less\" ;\n\n.signinModal {\n\twidth: 100%;\n\tmax-width: 30rem !important;\n\n\t@media (max-width: 576px) {\n    \tpadding: 0 !important;\n\t}\n}\n\n.linkContainer {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\n.link {\n\ttext-align: right;\n\ttext-decoration: underline;\n\tcolor: grey;\n}\n\n.link:hover {\n\tcolor: #313131;\n\ttext-decoration: underline;\n}\n\n.bottomContainer {\n\twidth: 100%;\n\tpadding: 1rem 0 0 0;\n\tmargin-top: 2rem;\n\tborder-top: 0.0625rem solid #e5e5e5;\n\ttext-align: center;\n\n\t.boldText {\n\t\tfont-weight: 600;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signinModal": "signinModal-k4JA7",
	"linkContainer": "linkContainer-FVBcP",
	"link": "link-Za7yF",
	"bottomContainer": "bottomContainer--UR2W",
	"boldText": "boldText-kmDZM"
};
export default ___CSS_LOADER_EXPORT___;

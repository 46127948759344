// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navContainer--EtSq .text-tn3pe {\n  font-family: fontm;\n  font-weight: 600;\n  font-size: 0.875rem;\n}\n.navContainer--EtSq .ant-menu-inline {\n  border-right: none;\n}\n.navContainer--EtSq .ant-menu-submenu-title {\n  padding-left: 0 !important;\n}\n.navContainer--EtSq .ant-menu-item-only-child {\n  padding-left: 0.4rem !important;\n}\n.navContainer--EtSq .ant-divider-horizontal {\n  margin: 1rem 0 0.5rem 0;\n}\n.navContainer--EtSq .ant-divider {\n  border-top: 1px solid rgba(0, 0, 0, 0.12);\n}\n", "",{"version":3,"sources":["webpack://src/page/HomePage/components/Nav/Nav.less"],"names":[],"mappings":"AAAA;EAEQ,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAR;AAJA;EASY,kBAAA;AAFZ;AAPA;EAaY,0BAAA;AAHZ;AAVA;EAiBY,+BAAA;AAJZ;AAbA;EAqBY,uBAAA;AALZ;AAhBA;EAyBY,yCAAA;AANZ","sourcesContent":[".navContainer {\n    .text {\n        font-family: fontm;\n        font-weight: 600;\n        font-size: .875rem;\n    }\n\n    :global {\n        .ant-menu-inline {\n            border-right: none;\n        }\n\n        .ant-menu-submenu-title {\n            padding-left: 0 !important;\n        }\n\n        .ant-menu-item-only-child {\n            padding-left: 0.4rem !important;\n        }\n\n        .ant-divider-horizontal {\n            margin: 1rem 0 0.5rem 0;\n        }\n\n        .ant-divider {\n            border-top: 1px solid rgba(0, 0, 0, 0.12);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navContainer": "navContainer--EtSq",
	"text": "text-tn3pe"
};
export default ___CSS_LOADER_EXPORT___;

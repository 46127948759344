// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.otherlink-9ZhDK {\n  width: 100%;\n  text-align: right;\n}\n.otherlink-9ZhDK a {\n  color: #000;\n  font-size: 14px;\n}\n.iconContainer-pEGrX {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 20px;\n}\n.iconContainer-pEGrX .icon-PPVPZ {\n  font-size: 20px;\n}\n.button-eMqC5 {\n  margin-top: 1.5rem;\n}\n.bottomContainer-nYzaX {\n  width: 100%;\n  padding: 2rem 0;\n  margin-top: 2rem;\n  border-top: 0.0625rem solid #e5e5e5;\n  text-align: center;\n}\n.bottomContainer-nYzaX .boldText-Ime3U {\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://src/page/Register/Register.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAA7F;EACC,WAAA;EACA,iBAAA;AAED;AAJA;EAIE,WAAA;EACA,eAAA;AAGF;AACA;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;AACD;AAJA;EAKE,eAAA;AAEF;AACA;EACC,kBAAA;AACD;AACA;EACC,WAAA;EACA,eAAA;EACA,gBAAA;EACA,mCAAA;EACA,kBAAA;AACD;AANA;EAQE,gBAAA;AACF","sourcesContent":["@import \"~@/util/util.less\" ;\n\n\n.otherlink {\n\twidth: 100%;\n\ttext-align: right;\n\ta {\n\t\tcolor: #000;\n\t\tfont-size: 14px;\n\t}\n}\n\n.iconContainer {\n\tdisplay: flex;\n\tjustify-content: center;\n\tmargin-bottom: 20px;\n\t.icon {\n\t\tfont-size: 20px;\n\t}\n}\n.button {\n\tmargin-top: 1.5rem;\n}\n.bottomContainer {\n\twidth: 100%;\n\tpadding: 2rem 0;\n\tmargin-top: 2rem;\n\tborder-top: 0.0625rem solid #e5e5e5;\n\ttext-align: center;\n\n\t.boldText {\n\t\tfont-weight: 600;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"otherlink": "otherlink-9ZhDK",
	"iconContainer": "iconContainer-pEGrX",
	"icon": "icon-PPVPZ",
	"button": "button-eMqC5",
	"bottomContainer": "bottomContainer-nYzaX",
	"boldText": "boldText-Ime3U"
};
export default ___CSS_LOADER_EXPORT___;

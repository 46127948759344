// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.container-P5-oD {\n  width: 100%;\n  display: flex;\n}\n.container-P5-oD .nav-mzAq8 {\n  padding-top: 2.2rem;\n  flex-basis: 14rem;\n  flex-shrink: 0;\n  padding-right: 2rem;\n}\n.container-P5-oD .wrapper-ekvY- {\n  flex-grow: 1;\n}\n@media (max-width: 992px) {\n  .container-P5-oD {\n    display: block;\n  }\n  .container-P5-oD .nav-mzAq8 {\n    padding-top: 0;\n    padding-right: 0;\n    padding-bottom: 2rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/page/User/User.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAD7F;EACC,WAAA;EACA,aAAA;AAGD;AALA;EAKE,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AAGF;AAXA;EAYE,YAAA;AAEF;AACC;EAAA;IACC,cAAA;EAEA;EAHD;IAGE,cAAA;IACA,gBAAA;IACA,oBAAA;EAGD;AACF","sourcesContent":["@import \"~@/util/util.less\";\n\n.container {\n\twidth: 100%;\n\tdisplay: flex;\n\n\t.nav {\n\t\tpadding-top: 2.2rem;\n\t\tflex-basis: 14rem;\n\t\tflex-shrink: 0;\n\t\tpadding-right: 2rem;\n\t}\n\t\n\t.wrapper {\n\t\tflex-grow: 1;\n\t}\n\t\n\t@media (max-width: @screen-lg) {\n\t\tdisplay: block;\n\t\t.nav {\n\t\t\tpadding-top: 0;\n\t\t\tpadding-right: 0;\n\t\t\tpadding-bottom: 2rem;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container-P5-oD",
	"nav": "nav-mzAq8",
	"wrapper": "wrapper-ekvY-"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container-PDkgx {\n  max-width: 60rem !important;\n  font-size: 1.2rem;\n  text-align: center;\n}\n.container-PDkgx .description-VW7vX {\n  margin: 2rem 0;\n}\n", "",{"version":3,"sources":["webpack://src/components/Confirmation/Confirmation.less"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAJA;EAKQ,cAAA;AAER","sourcesContent":[".container {\n    max-width: 60rem !important;\n    font-size: 1.2rem;\n    text-align: center;\n    .description {\n        margin: 2rem 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container-PDkgx",
	"description": "description-VW7vX"
};
export default ___CSS_LOADER_EXPORT___;

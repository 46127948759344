// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.container-xGk8n .form-0Hs4L {\n  max-width: 40rem;\n}\n.container-xGk8n .button-R5lEX {\n  display: flex;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://src/page/User/components/ShippingInformation/Shipping.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAF7F;EAEE,gBAAA;AAGF;AALA;EAKE,aAAA;EACA,uBAAA;AAGF","sourcesContent":["@import \"~@/util/util.less\";\n.container {\n\t.form {\n\t\tmax-width: 40rem;\n\t}\n\t.button {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container-xGk8n",
	"form": "form-0Hs4L",
	"button": "button-R5lEX"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.footer-tCmHn {\n  padding: 20px 0;\n  border-top: 1px solid rgba(30, 35, 42, 0.06);\n  box-shadow: 0 4px 5px 5px rgb(0 0 0%);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.footer-tCmHn .text-5iqt8 {\n  font-family: Gotham Book;\n  font-size: 16px;\n}\n.footer-tCmHn .icons-eSn\\+a {\n  display: flex;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://src/components/Footer/Footer.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAD7F;EACC,eAAA;EACA,4CAAA;EACA,qCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAGD;AATA;EASE,wBAAA;EACA,eAAA;AAGF;AAbA;EAcE,aAAA;EACA,uBAAA;AAEF","sourcesContent":["@import \"~@/util/util.less\";\n\n.footer {\n\tpadding: 20px 0;\n\tborder-top: 1px solid rgba(30,35,42,.06);\n\tbox-shadow: 0 4px 5px 5px rgb(0 0 0 / 5%);\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\n\t.text {\n\t\tfont-family: Gotham Book;\n\t\tfont-size: 16px;\n\t}\n\n\t.icons {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer-tCmHn",
	"text": "text-5iqt8",
	"icons": "icons-eSn+a"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n@media (min-width: 576px) {\n  .container-oWNKq .form-uqCiA {\n    max-width: 30rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/page/ForgotPassword/ForgotPassword.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAD7F;EACI;IAEQ,gBAAA;EAEV;AACF","sourcesContent":["@import \"~@/util/util.less\";\n\n@media (min-width: @screen-sm) {\n    .container {\n        .form {\n            max-width: 30rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container-oWNKq",
	"form": "form-uqCiA"
};
export default ___CSS_LOADER_EXPORT___;

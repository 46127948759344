// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".errorContainer-6tUIu {\n  padding-bottom: 10rem;\n}\n", "",{"version":3,"sources":["webpack://src/page/Error/Error.less"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ","sourcesContent":[".errorContainer {\n    padding-bottom: 10rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorContainer": "errorContainer-6tUIu"
};
export default ___CSS_LOADER_EXPORT___;

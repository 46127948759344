// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.homePageContainer-cSQbv {\n  min-height: 100%;\n  padding: 1rem 1rem;\n  max-width: 80rem;\n  margin: 0 auto;\n}\n.homePageContainer-cSQbv .main-e06pX {\n  display: flex;\n  margin-top: 1rem;\n  width: 100%;\n}\n@media (max-width: 768px) {\n  .homePageContainer-cSQbv {\n    width: 100%;\n    padding: 1rem 2rem;\n  }\n}\n@media (max-width: 576px) {\n  .homePageContainer-cSQbv {\n    width: 100%;\n    padding: 1rem 1rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/layout/ShopLayout/ShopLayout.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAD7F;EACC,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;AAGD;AAPA;EAOE,aAAA;EACA,gBAAA;EACA,WAAA;AAGF;AAAC;EAAA;IACC,WAAA;IACA,kBAAA;EAGA;AACF;AADC;EAAA;IACC,WAAA;IACA,kBAAA;EAIA;AACF","sourcesContent":["@import \"~@/util/util.less\";\n\n.homePageContainer {\n\tmin-height: 100%;\n\tpadding: 1rem 1rem;\n\tmax-width: 80rem;\n\tmargin: 0 auto;\n\n\t.main {\n\t\tdisplay: flex;\n\t\tmargin-top: 1rem;\n\t\twidth: 100%;\n\t}\n\n\t@media (max-width: @screen-md) {\n\t\twidth: 100%;\n\t\tpadding: 1rem 2rem;\n\t}\n\n\t@media (max-width: @screen-sm) {\n\t\twidth: 100%;\n\t\tpadding: 1rem 1rem;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homePageContainer": "homePageContainer-cSQbv",
	"main": "main-e06pX"
};
export default ___CSS_LOADER_EXPORT___;

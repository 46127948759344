// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.linkContainer-LZYeE {\n  display: flex;\n  justify-content: space-between;\n}\n.link-71vV3 {\n  text-align: right;\n  text-decoration: underline;\n  color: grey;\n}\n.link-71vV3:hover {\n  color: #313131;\n  text-decoration: underline;\n}\n.bottomContainer-ARl49 {\n  width: 100%;\n  padding: 2rem 0;\n  margin-top: 2rem;\n  border-top: 0.0625rem solid #e5e5e5;\n  text-align: center;\n}\n.bottomContainer-ARl49 .boldText-Bwx84 {\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://src/page/Login/Login.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAD7F;EACC,aAAA;EACA,8BAAA;AAGD;AAAA;EACC,iBAAA;EACA,0BAAA;EACA,WAAA;AAED;AACA;EACC,cAAA;EACA,0BAAA;AACD;AAEA;EACC,WAAA;EACA,eAAA;EACA,gBAAA;EACA,mCAAA;EACA,kBAAA;AAAD;AALA;EAQE,gBAAA;AAAF","sourcesContent":["@import \"~@/util/util.less\";\n\n.linkContainer {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\n.link {\n\ttext-align: right;\n\ttext-decoration: underline;\n\tcolor: grey;\n}\n\n.link:hover {\n\tcolor: #313131;\n\ttext-decoration: underline;\n}\n\n.bottomContainer {\n\twidth: 100%;\n\tpadding: 2rem 0;\n\tmargin-top: 2rem;\n\tborder-top: 0.0625rem solid #e5e5e5;\n\ttext-align: center;\n\n\t.boldText {\n\t\tfont-weight: 600;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkContainer": "linkContainer-LZYeE",
	"link": "link-71vV3",
	"bottomContainer": "bottomContainer-ARl49",
	"boldText": "boldText-Bwx84"
};
export default ___CSS_LOADER_EXPORT___;
